import {qrcodeAction} from "./store";
import HashJson from "../../core/utils/hash-json";
import createCopyConfig from "../../core/utils/createCopyConfig";
import callPeerRemoteModule from "../../core/utils/callPeerRemoteModule";
const hashJson = new HashJson();


const webglStateMiddleware = (store) => (next) => (action) => {
  if (action.type === "optionWindow/setSendData" || action.type === "optionWindow/setSendDataRemove" || action.type === "optionWindow/setLoadData") {
    Promise.all([next(action)]).then(() => {
      const dispatch = store.dispatch;
      const state = store.getState()["optionWindow"];
      const themeState = store.getState()["theme"];

      let {THEME, ...etc} = state.CONFIG_DATA;

      let copyConfigData = JSON.parse(JSON.stringify(state.CONFIG_DATA));
      const configDataKeys = Object.keys(copyConfigData);
      const removeNotesData = createCopyConfig(copyConfigData, configDataKeys);
      let configIncludingProductId;

      if (state.currentVehicle) {
        configIncludingProductId = {productId : state.currentVehicle.id, ...removeNotesData };
      }
      configIncludingProductId && dispatch(qrcodeAction.setQRcode(hashJson.hash(configIncludingProductId).substring(0, 7)));

      let webglPayload = state.CONFIG_DATA;
      const loading = store.getState()["intro"].loadingState;



      if (action.payload.key === 'THEME') {
        if (THEME && THEME.CODE === 'BLACK'){
          webglPayload = { [action.payload.key] : THEME };
        }
      } else {
        if (THEME && THEME.CODE === 'STANDARD') {
          webglPayload = etc;
        }
      }



      if (themeState.connectMode === "remote") {
        (async () => {
          const peer = await callPeerRemoteModule(themeState.connectMode);
          peer.conn.sendData("sendData", webglPayload);
          peer.conn.sendData("dispatch", { action: "introAction", func: "setLoadingState", payload: loading});
        })();

      } else if (themeState.connectMode === "web") {

        if (window && (webglPayload || loading === "assetLoad:end")) {
          window.postMessage(webglPayload, "*");
        }
      }
    })
  } else {
    next(action);
  }
};

export default webglStateMiddleware;
